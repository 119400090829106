import React, { useState } from "react";
import { Link } from "gatsby"
import Img01 from "../../../images/Office/contact-img.jpg"
import ScrollAnimation from 'react-animate-on-scroll';
import Select from "react-dropdown-select";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import "./Connect.scss";
import CarrerFrom from '../../forms/career-form';
import GetStaff from "../../get-staff-strapi";
const Connect = (props) => {
    const Departments = [
        { value: "Option", label: "Option" },
        { value: "Option1", label: "Option1" },
        { value: "Option2", label: "Option2" },
        { value: "All Offices", label: "All Offices" },
    ];
    var team = props.member
    let processedImages = JSON.stringify({});
    if (team?.imagetransforms?.Staff_Image_Transforms) {
    processedImages = team.imagetransforms.Staff_Image_Transforms;
    }
    return (
        <React.Fragment>
            <section className="connect-us" id="send-cv">
                <Container>
                    <Row>
                        <Col>
                            <div className="connect-wrapper">
                                <div className="contact-info">
                                    
                                        <h2>Send us your CV</h2>
                                        <p>We are looking for candidates with the right experience and attitude. If you want to join our business or find out more about the vacancies, please send your CV.</p>
                                            {/* <GetStaff hidecta="true" member={props.member} contactclass="contact" class="contact-details d-none d-lg-block"/> */}
                                    
                                </div>
                                <div className="form-wrapper">
                                    
                                        <CarrerFrom jobname={props.jobname} type={props.type} toemail={props.toemail}/>
                                        {/* <Form>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Control type="text" placeholder="Your Name" />
                                            </Form.Group>

                                            <Form.Group className="mb-3 branches" controlId="formBasicEmail">

                                                <Select className="departments form-control" placeholder="All Offices" searchable="false"
                                                    options={Departments}
                                                    values={[]}
                                                />
                                            </Form.Group>

                                            <div className="group-wrapper">
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Control type="email" placeholder="Your email" />
                                                </Form.Group>

                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Control type="number" placeholder="Your phone number" />
                                                </Form.Group>
                                            </div>

                                            <Form.Group className="mb-3" controlId="formBasicEmail" className="upload">
                                                <label  className="form-control">
                                                    <input type="file" />
                                                    Upload your CV
                                                </label>
                                            </Form.Group>

                                            <Form.Group controlId="exampleForm.ControlTextarea1" >
                                                <Form.Control placeholder="Your cover letter" as="textarea" rows={3} className="area" />
                                            </Form.Group>
                                            {['checkbox'].map((type) => (
                                                <div key={`inline-${type}`} className="mb-3 data">
                                                    <Form.Check
                                                        inline
                                                        name="group1"
                                                        type={type}
                                                        id={`inline-${type}-2`}
                                                    />
                                                    <span className="form-check-label">
                                                        I have read and agree to the <Link to="#"> Terms and
                                                            Conditions, </Link> <Link to="#"> Privacy Policy </Link> and <Link to="#"> Cookies Policy. </Link>
                                                    </span>
                                                </div>
                                            ))}
                                            <Button variant="primary" type="submit">
                                                Submit details </Button>
                                        </Form> */}
                                    
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>

    )
}
export default Connect



